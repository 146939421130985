import { Service } from './service'

const PATH = '2way/'

class TwoWayService extends Service {
    getInbox (params) {
        return this.get(PATH + 'inbox', params)
    }

    check (params) {
        return this.post(PATH + 'check', params)
    }

    removeByIds (params) {
        return this.post(PATH + 'delete-by-ids', params)
    }

    removeDiferentIds (params) {
        return this.post(PATH + 'delete-different-ids', params)
    }

    removeAll () {
        return this.post(PATH + 'delete-all')
    }
}

export default new TwoWayService()
