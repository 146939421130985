import EventBus from '@/util/EventBus'
import { ExportForm } from '@/classes/ExportForm'
import getEnv from '@/util/env'
import { GlobalControl } from '@/classes/stats/GlobalControl'
import TwoWayService from '@/services/two-way.service.js'
import DateRange from '@/components/DateRange/DateRange.vue'
import Alert from '@/components/Alert/Alert.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'TwoWay',
  components: { Alert, DateRange, HeaderTopDashboard },
  data () {
    return {
      showRemoveDialog: false,
      selected: [],
      selectedAction: 1,
      messages: [],
      selectedIds: [],
      totalItems: 0,
      pageCount: 0,
      totalPages: 0,
      searchTerm: '',
      itemsPerPage: 10,
      total: 0,
      page: 1,
      options: {},
      globalControl: new GlobalControl(),
      dates: [],
      exportForm: new ExportForm(),
    }
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    headers () {
      return [
        { text: this.$t('País'), align: 'start', value: 'countryName' },
        { text: this.$t('Teléfono'), align: 'start', value: 'sourceAddr' },
        { text: this.$t('Remitente'), align: 'start', value: 'destAddr' },
        { text: this.$t('mensaje'), align: 'start', value: 'message' },
        { text: this.$t('Fecha').toString().toLowerCase(), align: 'start', value: 'date' },
      ]
    },
    actionOptions () {
      return [
        { text: this.$t('Seleccionados').toString(), value: 1 },
        { text: this.$t('No seleccionados').toString(), value: 2 },
        { text: this.$t('Todos (Sin tener en cuenta la paginación)').toString(), value: 3 },
      ]
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
    selected: {
      handler () {
        this.setSelectedIds()
      },
      deep: true,
    },
  },
  mounted () {
    this.setParamsAndGetData()
  },
  methods: {
    changeGlobalControl (dates) {
      if (Date.parse(dates[0]) > Date.parse(dates[1])) {
        this.globalControl.initDate = dates[1]
        this.globalControl.endDate = dates[0]
      } else {
        this.globalControl.initDate = dates[0]
        this.globalControl.endDate = dates[1]
      }
      this.setParamsAndGetData()
    },
    setParamsAndGetData () {
      const params = this.getParams()
      this.getDataFromApi(params)
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      return params
    },
    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },
    downloadMessages () {
      if (this.messages.length) {
        const user = JSON.parse(localStorage.getItem('user'))
        const url = getEnv('VUE_APP_API_URL') + 'api/2way/download-csv?token=' + user.token
        window.location.href = url
      } else {
        EventBus.$emit('showAlert', 'success', this.$t('No se han encontrado mensajes').toString() + '.')
      }
    },
    downloadMessagesById () {
      if (this.messages.length) {
        const user = JSON.parse(localStorage.getItem('user'))
        var ids = this.selectedIds.join('-')

        switch (this.selectedAction) {
          case 1:
            window.location.href = getEnv('VUE_APP_API_URL') + 'api/2way/download-csv-by-id/' + ids + '?token=' + user.token
            break

          case 2:
            window.location.href = getEnv('VUE_APP_API_URL') + 'api/2way/download-csv-by-not-id/' + ids + '?token=' + user.token
            break

          case 3:
            this.setExportData()
            window.location.href = getEnv('VUE_APP_API_URL') + 'api/2way/download-filtered-csv?' + this.exportForm.getUrlParams()
            break
        }
      } else {
        EventBus.$emit('showAlert', 'success', this.$t('No se han encontrado mensajes').toString() + '.')
      }
    },

    removeMessageAlert () {
      EventBus.$emit('showAlert', 'success', this.$t('Se han eliminado los mensajes correctamente'))
    },

    endRemoveProcess () {
      this.showRemoveDialog = false
      this.setParamsAndGetData()
      this.removeMessageAlert()
      this.selectedIds = []
    },

    removeMessages () {
      switch (this.selectedAction) {
        case 1:
          TwoWayService.removeByIds({ ids: this.selectedIds }).then(() => {
            this.endRemoveProcess()
          })
          break

        case 2:
          TwoWayService.removeDiferentIds({ ids: this.selectedIds }).then(() => {
            this.endRemoveProcess()
          })
          break

        case 3:
          TwoWayService.removeAll().then(() => {
            this.endRemoveProcess()
          })
          break
      }
    },

    setSelectedIds () {
      this.selectedIds = []
      this.selected.forEach(message => {
        this.selectedIds.push(message.id)
      })
    },

    getDataFromApi (params) {
      EventBus.$emit('showLoading', true)
      TwoWayService.getInbox(params).then((response) => {
        this.messages = response.data.messages.data
        this.itemsPerPage = parseInt(response.data.messages.per_page)
        this.page = response.data.messages.current_page
        this.total = response.data.messages.total
        this.totalPages = response.data.messages.last_page
      }, () => {

      })
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },
  },
}
